var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("span", { staticClass: "d-block fw-bold f15" }, [
        _vm._v(_vm._s(_vm.$t("Operation report"))),
      ]),
      _c(
        "v-list",
        {
          staticClass: "w-100 p-0 border rounded-lg mt-3",
          attrs: { light: "", dense: "" },
        },
        [
          _c(
            "v-list-item",
            {
              staticClass: "py-1",
              attrs: {
                link: "",
                ripple: "",
                to: `/s/clinic/${_vm.clinicId}/report/financial`,
              },
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("Financial"))),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c("iconly", {
                    attrs: {
                      type: "light",
                      name: "arrow-left2",
                      color: "#757575",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-1",
              attrs: {
                link: "",
                ripple: "",
                to: `/s/clinic/${_vm.clinicId}/report/contents`,
              },
            },
            [
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v(_vm._s(_vm.$t("Content")))])],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c("iconly", {
                    attrs: {
                      type: "light",
                      name: "arrow-left2",
                      color: "#757575",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-1",
              attrs: {
                link: "",
                ripple: "",
                to: `/s/clinic/${_vm.clinicId}/report/exams`,
              },
            },
            [
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v(_vm._s(_vm.$t("Exam")))])],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c("iconly", {
                    attrs: {
                      type: "light",
                      name: "arrow-left2",
                      color: "#757575",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-1",
              attrs: {
                link: "",
                ripple: "",
                to: `/s/clinic/${_vm.clinicId}/report/exercises`,
              },
            },
            [
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v(_vm._s(_vm.$t("Exercise")))])],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c("iconly", {
                    attrs: {
                      type: "light",
                      name: "arrow-left2",
                      color: "#757575",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-1",
              attrs: {
                link: "",
                ripple: "",
                to: `/s/clinic/${_vm.clinicId}/report/questions`,
              },
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("Questions and answers"))),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c("iconly", {
                    attrs: {
                      type: "light",
                      name: "arrow-left2",
                      color: "#757575",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              staticClass: "py-1",
              attrs: {
                link: "",
                ripple: "",
                to: `/s/clinic/${_vm.clinicId}/report/schedules`,
              },
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("Make an appointment"))),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c("iconly", {
                    attrs: {
                      type: "light",
                      name: "arrow-left2",
                      color: "#757575",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-list-item",
        {
          staticClass: "my-1",
          attrs: {
            link: "",
            ripple: "",
            to: `/s/clinic/${_vm.clinicId}/members`,
          },
        },
        [
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "f15 fw-bold" }, [
                _vm._v(_vm._s(_vm.$t("Members"))),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item-action",
            [
              _c("iconly", {
                attrs: { type: "light", name: "arrow-left2", color: "#757575" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-list-item",
        {
          staticClass: "my-1",
          attrs: { link: "", ripple: "", to: `/s/clinic/${_vm.clinicId}/edit` },
        },
        [
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "f15 fw-bold" }, [
                _vm._v(_vm._s(_vm.$t("Edit information"))),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item-action",
            [
              _c("iconly", {
                attrs: { type: "light", name: "arrow-left2", color: "#757575" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex my-2 align-items-center" },
        [
          _c("span", { staticClass: "ms-0 me-3 fw-bold f14" }, [
            _vm._v(_vm._s(_vm.$t("Schedule a patient"))),
          ]),
          _c("v-divider"),
        ],
        1
      ),
      _c(
        "v-list-item",
        { staticClass: "px-0 mx-0 d-none" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "ms-0 me-2" },
            [
              _c(
                "v-sheet",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center rounded-lg",
                  attrs: {
                    color: "#EEE8FF",
                    elevation: "0",
                    height: "36",
                    width: "36",
                  },
                },
                [
                  _c("iconly", {
                    attrs: { type: "bulk", name: "calendar", color: "#7E55F3" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item-content",
            {},
            [
              _c("v-list-item-title", { staticClass: "font-weight-bold f13" }, [
                _vm._v(_vm._s(_vm.$t("Emergency call"))),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item-action",
            { staticClass: "d-flex flex-row align-items-center" },
            [
              _c("toggle-button", {
                attrs: {
                  value: _vm.emergencyCallStatus,
                  sync: true,
                  labels: {
                    checked: _vm.$t("Active"),
                    unchecked: _vm.$t("Inactive"),
                  },
                  "font-size": 12,
                  height: 35,
                  width: 85,
                  color: {
                    checked: "#20B1EA",
                    unchecked: "#CCCCCC",
                    disabled: "#CCCCCC",
                  },
                },
                on: { change: _vm.onChangeEmergencyCallStatus },
              }),
              _c("iconly", {
                staticClass: "ms-2 me-0",
                attrs: { type: "light", name: "arrow-left2", color: "#757575" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-list-item",
        { staticClass: "px-0 mx-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "ms-0 me-2" },
            [
              _c(
                "v-sheet",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center rounded-lg",
                  attrs: {
                    color: "#EEE8FF",
                    elevation: "0",
                    height: "36",
                    width: "36",
                  },
                },
                [
                  _c("iconly", {
                    attrs: { type: "bulk", name: "calendar", color: "#7E55F3" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "font-weight-bold f13" }, [
                _vm._v(_vm._s(_vm.$t("Online"))),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item-action",
            { staticClass: "d-flex flex-row align-items-center" },
            [
              _c("toggle-button", {
                attrs: {
                  value: _vm.onlineCallStatus,
                  sync: true,
                  labels: {
                    checked: _vm.$t("Active"),
                    unchecked: _vm.$t("Inactive"),
                  },
                  "font-size": 12,
                  height: 35,
                  width: 85,
                  color: {
                    checked: "#20B1EA",
                    unchecked: "#CCCCCC",
                    disabled: "#CCCCCC",
                  },
                },
                on: { change: _vm.onChangeOnlineCallStatus },
              }),
              _c("iconly", {
                staticClass: "ms-2 me-0",
                attrs: { type: "light", name: "arrow-left2", color: "#757575" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-list-item",
        { staticClass: "px-0 mx-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "ms-0 me-2" },
            [
              _c(
                "v-sheet",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center rounded-lg",
                  attrs: {
                    color: "#EEE8FF",
                    elevation: "0",
                    height: "36",
                    width: "36",
                  },
                },
                [
                  _c("iconly", {
                    attrs: { type: "bulk", name: "calendar", color: "#7E55F3" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "font-weight-bold f13" }, [
                _vm._v(_vm._s(_vm.$t("Telephone"))),
              ]),
            ],
            1
          ),
          _c(
            "v-list-item-action",
            { staticClass: "d-flex flex-row align-items-center" },
            [
              _c("toggle-button", {
                attrs: {
                  value: _vm.phoneCallStatus,
                  sync: true,
                  labels: {
                    checked: _vm.$t("Active"),
                    unchecked: _vm.$t("Inactive"),
                  },
                  "font-size": 12,
                  height: 35,
                  width: 85,
                  color: {
                    checked: "#20B1EA",
                    unchecked: "#CCCCCC",
                    disabled: "#CCCCCC",
                  },
                },
                on: { change: _vm.onChangePhoneCallStatus },
              }),
              _c("iconly", {
                staticClass: "ms-2 me-0",
                attrs: { type: "light", name: "arrow-left2", color: "#757575" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }