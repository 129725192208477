<template>
  <section>
    <span class="d-block fw-bold f15">{{$t('Operation report')}}</span>
    <v-list class="w-100 p-0 border rounded-lg mt-3" light dense>

      <v-list-item class="py-1" link ripple :to="`/s/clinic/${clinicId}/report/financial`">
        <v-list-item-content>
          <v-list-item-title>{{ $t('Financial') }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <iconly type="light" name="arrow-left2" color="#757575" />
        </v-list-item-action>
      </v-list-item>

      <v-list-item class="py-1" link ripple :to="`/s/clinic/${clinicId}/report/contents`">
        <v-list-item-content>
          <v-list-item-title>{{ $t('Content') }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <iconly type="light" name="arrow-left2" color="#757575" />
        </v-list-item-action>
      </v-list-item>
      
      <v-list-item class="py-1" link ripple :to="`/s/clinic/${clinicId}/report/exams`">
        <v-list-item-content>
          <v-list-item-title>{{ $t('Exam') }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <iconly type="light" name="arrow-left2" color="#757575" />
        </v-list-item-action>
      </v-list-item>
      
      <v-list-item class="py-1" link ripple :to="`/s/clinic/${clinicId}/report/exercises`">
        <v-list-item-content>
          <v-list-item-title>{{ $t('Exercise') }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <iconly type="light" name="arrow-left2" color="#757575" />
        </v-list-item-action>
      </v-list-item>
      
      <v-list-item class="py-1" link ripple :to="`/s/clinic/${clinicId}/report/questions`">
        <v-list-item-content>
          <v-list-item-title>{{ $t('Questions and answers') }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <iconly type="light" name="arrow-left2" color="#757575" />
        </v-list-item-action>
      </v-list-item>
      
      <v-list-item class="py-1" link ripple :to="`/s/clinic/${clinicId}/report/schedules`">
        <v-list-item-content>
          <v-list-item-title>{{ $t('Make an appointment') }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <iconly type="light" name="arrow-left2" color="#757575" />
        </v-list-item-action>
      </v-list-item>

    </v-list>

    <v-list-item class="my-1" link ripple :to="`/s/clinic/${clinicId}/members`">
      <v-list-item-content>
        <v-list-item-title class="f15 fw-bold">{{ $t('Members') }}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <iconly type="light" name="arrow-left2" color="#757575" />
      </v-list-item-action>
    </v-list-item>

    <v-list-item class="my-1" link ripple :to="`/s/clinic/${clinicId}/edit`">
      <v-list-item-content>
        <v-list-item-title class="f15 fw-bold">{{ $t('Edit information') }}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <iconly type="light" name="arrow-left2" color="#757575" />
      </v-list-item-action>
    </v-list-item>

    <!-- ------------------------ -->
    <!-- Schedule -->
    <div class="d-flex my-2 align-items-center">
      <span class="ms-0 me-3 fw-bold f14">{{$t('Schedule a patient')}}</span>
      <v-divider></v-divider>
    </div>

    <v-list-item class="px-0 mx-0 d-none">
      <v-list-item-icon class="ms-0 me-2">
        <v-sheet color="#EEE8FF" elevation="0" height="36" width="36"
                 class="d-flex align-items-center justify-content-center rounded-lg">
          <iconly type="bulk" name="calendar" color="#7E55F3" />
        </v-sheet>
      </v-list-item-icon>
      <v-list-item-content class="">
        <v-list-item-title class="font-weight-bold f13">{{ $t('Emergency call') }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action class="d-flex flex-row align-items-center">
        <toggle-button
            @change="onChangeEmergencyCallStatus"
            :value="emergencyCallStatus"
            :sync="true"
            :labels="{checked: $t('Active'), unchecked: $t('Inactive')}"
            :font-size="12"
            :height="35"
            :width="85"
            :color="{checked: '#20B1EA', unchecked: '#CCCCCC', disabled: '#CCCCCC'}"
        >
        </toggle-button>
        <iconly type="light" name="arrow-left2" color="#757575" class="ms-2 me-0" />
      </v-list-item-action>
    </v-list-item>

    <v-list-item class="px-0 mx-0">
      <v-list-item-icon class="ms-0 me-2">
        <v-sheet color="#EEE8FF" elevation="0" height="36" width="36"
                 class="d-flex align-items-center justify-content-center rounded-lg">
          <iconly type="bulk" name="calendar" color="#7E55F3" />
        </v-sheet>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold f13">{{ $t('Online') }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action class="d-flex flex-row align-items-center">
        <toggle-button
            @change="onChangeOnlineCallStatus"
            :value="onlineCallStatus"
            :sync="true"
            :labels="{checked: $t('Active'), unchecked: $t('Inactive')}"
            :font-size="12"
            :height="35"
            :width="85"
            :color="{checked: '#20B1EA', unchecked: '#CCCCCC', disabled: '#CCCCCC'}"
        >
        </toggle-button>
        <iconly type="light" name="arrow-left2" color="#757575" class="ms-2 me-0" />
      </v-list-item-action>
    </v-list-item>

    <v-list-item class="px-0 mx-0">
      <v-list-item-icon class="ms-0 me-2">
        <v-sheet color="#EEE8FF" elevation="0" height="36" width="36"
                 class="d-flex align-items-center justify-content-center rounded-lg">
          <iconly type="bulk" name="calendar" color="#7E55F3" />
        </v-sheet>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold f13">{{ $t('Telephone') }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action class="d-flex flex-row align-items-center">
        <toggle-button
            @change="onChangePhoneCallStatus"
            :value="phoneCallStatus"
            :sync="true"
            :labels="{checked: $t('Active'), unchecked: $t('Inactive')}"
            :font-size="12"
            :height="35"
            :width="85"
            :color="{checked: '#20B1EA', unchecked: '#CCCCCC', disabled: '#CCCCCC'}"
        >
        </toggle-button>
        <iconly type="light" name="arrow-left2" color="#757575" class="ms-2 me-0" />
      </v-list-item-action>
    </v-list-item>

  </section>
</template>

<script>

import apiService from "@/http/ApiService";

export default {
  data() {
    return {
      clinicId: null,

      emergencyCallStatus: false,
      onlineCallStatus: false,
      phoneCallStatus: false,
    }
  },
  created(){
    this.getDoctorInfoByMyMobile();
  },
  methods: {
    getDoctorInfoByMyMobile() {
      apiService.getDoctorInfoByMyMobile()
          .then((response) => {
            this.onlineCallStatus = response.data.onlineCallStatus;
            this.phoneCallStatus = response.data.phoneCallStatus;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    onChangeEmergencyCallStatus(){
      apiService.configSchedulingStatus("EMERGENCY_CALL", !this.emergencyCallStatus)
          .then(() => {
            this.emergencyCallStatus = !this.emergencyCallStatus;
          })
          .catch((err) => {
            console.log(err);
          })
    },
    onChangeOnlineCallStatus(){
      apiService.configSchedulingStatus("ONLINE_CALL", !this.onlineCallStatus)
          .then(() => {
            this.onlineCallStatus = !this.onlineCallStatus;
          })
          .catch((err) => {
            console.log(err);
          })
    },
    onChangePhoneCallStatus(){
      apiService.configSchedulingStatus("PHONE_CALL", !this.phoneCallStatus)
          .then(() => {
            this.phoneCallStatus = !this.phoneCallStatus;
          })
          .catch((err) => {
            console.log(err);
          })
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    if(this.$route.params.clinicId){
      this.clinicId = this.$route.params.clinicId;
    }
    else {
      this.$router.go(-1);
    }
  },
}
</script>
